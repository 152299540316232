import Alpine from 'alpinejs'
import intersect from '@alpinejs/intersect'
 
Alpine.plugin(intersect)

window.HomePagePercents = (amount) => {
	return {
		counter: 0,
		target: amount,
		animation: 0,
		duration: 0.0,

		percentCounter() {

			this.duration = 4000 / this.target; // Scroll percents for 4 seconds

			console.log('target- %f : duration- %f', this.target, this.duration);

			if (this.duration < 5) { // Starts higher for larger numbers
				this.counter = this.target - 800; 
				this.duration = 4000 / 800;
			}

			console.log('target- %f : duration- %f', this.target, this.duration);

			this.animation = setInterval (() => {
				if (this.counter < this.target) {
					this.counter++;
				} else {
					clearInterval(this.animation);
				}
			}, this.duration);
		}, 

	};
};
